import React from "react";

import { DataList } from "@/components";

/**
 * @typedef {Object} CarTechSpecsItem
 * @property {string} label - Label for the tech spec
 * @property {keyof import("@/api/generated/cars").CarDetailSchema} value - Key for the tech spec value
 * @property {string} [unit] - Optional unit for the tech spec value
 * @property {(value: any) => boolean} [hide] - Optional function to hide the tech spec based on the value
 */

/**
 * @type {CarTechSpecsItem[]}
 */
const carTechSpecs = [
  {
    label: "Vél",
    value: "engine_size_cc",
    unit: "cc",
    hide: (value) => String(value) === "0",
  },
  {
    label: "Skipting",
    value: "transmission",
  },
  {
    label: "Aflgjafi",
    value: "fuelType",
  },
  {
    label: "Drif",
    value: "drive",
  },
  {
    label: "Eyðsla frá",
    value: "consumption",
    unit: "l/100km",
  },
  {
    label: "Drægni",
    value: "range",
    unit: "km",
  },
  {
    label: "Stærð rafhlöðu",
    value: "battery_capacity",
    unit: "kWh",
  },
  {
    label: "Co2 losun frá",
    value: "co2_emission",
    unit: "g/100km",
  },
  {
    label: "Hestöfl",
    value: "horsepower",
    unit: "Hö",
  },
];

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarDetailSchema} props.car - Car to display details for
 */
export const CarDetailsSpecList = ({ car }) => {
  return (
    <ul className="car-details__specs-list">
      {carTechSpecs
        .filter(({ value }) => car[value])
        .filter(({ hide, value }) => !hide || !hide(car[value]))
        .map(({ label, value, unit }) => (
          <li className="car-details__specs-list__item" key={value}>
            <span className="car-details__specs-list__item__label">{label}</span>
            <div className="car-details__specs-list__item__container">
              <span className="car-details__specs-list__item__value">{car[value]}</span>
              {unit && <span className="car-details__specs-list__item__unit">{unit}</span>}
            </div>
          </li>
        ))}
    </ul>
  );
};

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarAttributeCategorySchema[]} props.categories
 */
export const CarDetailsAttributeList = ({ categories }) => {
  return (
    <ul className="car-details__attribute-list">
      {categories.map((category, index) => (
        <li key={index} className="car-details__attribute-list__category">
          {category.name && (
            <p className="car-details__attribute-list__category__name">{category.name}</p>
          )}
          <ul className="car-details__attribute-list__category__attributes">
            {category.attributes.map((attribute) => (
              <li
                className="car-details__attribute-list__category__attributes__attribute"
                key={attribute}
              >
                {attribute}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

/**
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema} props.variant
 */
export const CarDetailsPropertyList = ({ variant }) => {
  return (
    <div className="car-details__property-list">
      <DataList
        title="Vél og afköst"
        items={[
          {
            label: "Eldsneyti",
            value: variant.fuelType,
          },
          {
            label: "Fjöldi strokka",
            value: variant.number_of_cylinders,
          },
          {
            label: "Rúmtak vélar",
            value: variant.engine_size_cc,
          },
          {
            label: "Skipting",
            value: variant.transmission,
          },
          {
            label: "Drifás",
            value: variant.drive,
          },
          {
            label: "Hámarks hestöfl (HÖ)",
            value: variant.horsepower,
          },
          {
            label: "Hámarks tog (NM)",
            value: variant.max_torque,
          },
          {
            label: "Hröðun (0-100 Km/Klst.)",
            value: variant.acceleration,
          },
          {
            label: "Hámarkshraði",
            value: variant.top_speed,
          },
        ]}
      />
      <DataList
        title="Helstu mál"
        items={[
          {
            label: "Heildarlengd (mm)",
            value: variant.length,
          },
          {
            label: "Heildarbreidd með speglum (mm)",
            value: variant.width,
          },
          {
            label: "Heildarhæð (mm)",
            value: variant.height,
          },
          {
            label: "Hjólhaf (mm)",
            value: variant.wheel_base,
          },
          {
            label: "Hæð undir lægsta punkt (mm)",
            value: variant.ground_clearance,
          },
          {
            label: "Beygjuradíus",
            value: variant.turning_circle,
          },
          {
            label: "Farangursrými (l)",
            value: variant.luggage_compartment_min,
          },
          {
            label: "Farangursrými með sæti niðri (l)",
            value: variant.luggage_compartment_max,
          },
          {
            label: "Eiginþyngd (kg)",
            value: variant.kerb_weight,
          },
          {
            label: "Dráttargeta án hemla",
            value: variant.towing_capacity_braked,
          },
          {
            label: "Dráttargeta með hemlum",
            value: variant.towing_capacity_unbraked,
          },
        ]}
      />
      <DataList
        title="Eyðsla og útblástur"
        items={[
          {
            label: "Eyðsla",
            value: variant.consumption,
          },
          {
            label: "CO2 g/100 km",
            value: variant.co2_emission,
          },
          {
            label: "Stærð eldsneytistanks",
            value: variant.fuel_tank_capacity,
          },
        ]}
      />
      <DataList
        title="Annað"
        items={[
          {
            label: "Fjöldi sæta",
            value: variant.seats
          },
          {
            label: "IsoFix",
            value: variant.isofix_points,
          },
        ]}
      />      
    </div>
  );
};
